import React from 'react';
import "./src/assets/css/default.css";
import {createGlobalStyle} from 'styled-components';
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
  once: true,
  duration: 1000, // values from 0 to 3000, with step 50ms
});


const GlobalStyles = createGlobalStyle`

  [data-aos="new-animation"] {
    opacity: 0;
    transform: translate3d(0px, 13px, 0px);
    transition-property: transform, opacity;

    &.aos-animate {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }
`;

export const wrapRootElement = ({ element }) => (
  <div>
    <GlobalStyles />
    {element}
  </div>
);
